<template>
  <div v-loading="isLoading">
    <el-form
      v-if="formData"
      class="form"
      :model="formData"
      label-position="left"
      label-width="200px">
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.trialEbdn.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.trialEbdn.displayText">
        <el-switch v-model="formData.trialEbdn"></el-switch>
      </el-form-item>
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.creditCheck.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.creditCheck.displayText">
        <el-switch v-model="formData.creditCheck"></el-switch>
      </el-form-item>
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.cargoOfficerTransferJob.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.cargoOfficerTransferJob.displayText">
        <el-switch v-model="formData.cargoOfficerTransferJob"></el-switch>
      </el-form-item>
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.cargoOfficerCancelJob.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.cargoOfficerCancelJob.displayText">
        <el-switch v-model="formData.cargoOfficerCancelJob"></el-switch>
      </el-form-item>
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.sgTradexParticipantId.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.sgTradexParticipantId.displayText">
        <el-input style='width: 350px' size="medium" v-model="formData.sgTradexParticipantId"></el-input>
      </el-form-item>
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.pushB2BViaSgTradex.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.pushB2BViaSgTradex.displayText">
        <el-switch v-model="formData.pushB2BViaSgTradex"></el-switch>
      </el-form-item>
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.sgTradexPushAfter.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.sgTradexPushAfter.displayText">
        <el-date-picker
          v-model="formData.sgTradexPushAfter"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="Select date and time">
        </el-date-picker>
      </el-form-item>
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.pushToMpaViaSgTradex.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.pushToMpaViaSgTradex.displayText">
        <el-switch v-model="formData.pushToMpaViaSgTradex"></el-switch>
      </el-form-item>
      <!-- <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.pushB2BViaSgTradexToSelf.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.pushB2BViaSgTradexToSelf.displayText">
        <el-switch v-model="formData.pushB2BViaSgTradexToSelf"></el-switch>
      </el-form-item> -->
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.ebdnDefaultRecipients.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.ebdnDefaultRecipients.displayText">
        <el-select style='width: 350px' v-model="formData.ebdnDefaultRecipients" 
          allow-create 
          filterable 
          multiple
          default-first-option 
          placeholder="Enter Emails" @change="onEmailChange">
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.decimalPoints.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.decimalPoints.displayText">
        <el-input style='width: 350px' size="medium" v-model="formData.decimalPoints" type="number"></el-input>
      </el-form-item>
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.roundingDecimal.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.roundingDecimal.displayText">
        <el-select style='width: 350px' v-model="formData.roundingDecimal" default-first-option placeholder="Please Select">
          <el-option v-for="item in roundingDecimalOptions" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.setPricingTier.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.setPricingTier.displayText">
        <el-switch v-model="formData.pricingTierCheck"></el-switch>
      </el-form-item>
      <el-form-item
        v-if="showField(COMPANY_SETTINGS_PROPERTIES.exxonMobil.isRestricted)"
        class="flex-row"
        :label="COMPANY_SETTINGS_PROPERTIES.exxonMobil.displayText">
        <el-switch v-model="formData.isExxonMobil"></el-switch>
      </el-form-item>
      <el-form-item class="flex-end" style="margin-top:10px">
        <el-button class="primary-button" @click="updateSettings">Update</el-button>
        <el-button class="second-button" @click="getSettings">Reset</el-button>
      </el-form-item>
    </el-form>
    </div>
</template>

<script>
import { isValidEmail } from '@/utils'
import { COMPANY_SETTINGS_PROPERTIES } from '@/constants.js'
import { mapState } from 'vuex'

export default {
  name: "UpdateSettings",
  props: {
    sysOrganizationId: String,
  },
  data() {
    return {
      COMPANY_SETTINGS_PROPERTIES,
      formData: null,
      isLoading: false,
      roundingDecimalOptions: [
        {
          label: 'Round Off',
          value: 'ROUND_OFF'
        },
        {
          label: 'Ceiling',
          value: 'CEILING'
        },
        {
          label: 'Floor',
          value: 'FLOOR'
        },
      ]
    };
  },
  mounted() {
    this.getSettings()
  },
  computed: {
    ...mapState(['permissions']),
    hasSysAdminPermission () {
      return this.permissions.hasSysAdminPermission
    }
  },
  methods: {
    getSettings () {
      const url = `app/sysOrganization/${this.sysOrganizationId}/settings`;
      this.$request.get({ url }).then((res) => {
        if (res.code !== 1000) {
          this.$message.error('Error retriving the settings.')
          return
        }
        this.formData = res.data;
      });
    },
    updateSettings () {
      this.isLoading = true
      const url = `app/sysOrganization/${this.sysOrganizationId}/settings`;
      this.$request.put({
        url,
        data: this.formData
      }).then((res) => {
        if (res.code !== 1000) {
          this.$message.error('Error saving the settings.')
          return
        }
        this.$message.success('Successfully saved the settings.')
        this.formData = res.data
      }).finally(() => {
        this.isLoading = false
      })
    },
    showField (isRestricted) {
      return !isRestricted || (isRestricted && this.hasSysAdminPermission)
    },
    onEmailChange (emails) {
      emails.forEach(email => {
        if (!isValidEmail(email)) {
          this.$message.error('Invalid EBDN Default Email(s)')
          return
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../index.scss";

.form {
  margin-left: 20px;
  margin-right: 20px;
}
</style>
